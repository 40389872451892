import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    Layout
  }
})
export default class NotFound extends Vue {

  beforeCreate() {
    if(window.location.hostname != 'localhost'){
      window.location.href = '/pagina-nao-encontrada.php';
    }
  }

}
  